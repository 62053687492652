@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 1280px) {
   .footerInfo {
      text-align: center;
   }
   
   .footerLinks {
      margin-left: auto;
      margin-right: auto;
   }
}