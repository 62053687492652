@tailwind base;
@tailwind components;
@tailwind utilities;

.homeGameboardKpiWrapper {
  display: grid;
  grid-template-areas:
    "kpi"
    "gameboard";
  align-items: center;
  grid-row-gap: 1rem;
}

.homeGameboardWrapper {
  grid-area: gameboard;
  border-radius: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.homeKpiWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  grid-area: kpi;
  padding-right: 0;
}

@media screen and (min-width: 1536px) {
  .homeGameboardKpiWrapper {
    grid-template-areas: none;
    grid-template-columns: 1.2fr 0.8fr;
    grid-row-gap: 0rem;
    grid-column-gap: 2rem;
  }

  .homeGameboardWrapper {
    grid-column: 1/2;
    padding-left: 2rem;
    padding-right: 0rem;
  }

  .homeKpiWrapper {
    grid-column: 2/3;
    margin-bottom: 0;
    margin-top: 0;
    padding-right: 2rem;
  }
}
