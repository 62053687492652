.billing-card-enabled {
  border-radius: 8px;
  background: #fefefe !important;
  width: 33.33%;
  box-shadow: 0px 24px 48px -12px #1018282e !important;
}

.billing-header-enabled {
  background: #1d2939;
  padding: 1rem 0rem;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.billing-card-disabled {
  border-radius: 7.567px;
  opacity: 0.7 !important;
  background: #fff;
}

.billing-title-box {
  display: flex;
  padding: 8.513px 15.135px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.459px;
  border-radius: 94.591px;
  background: #f4e9f8;
}

.billing-title {
  text-align: center;
  font-family: Inter;
  font-size: 15.135px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 15.135px */
  letter-spacing: 0.151px;
  text-transform: uppercase;
  background: linear-gradient(281deg, #7928ca 0%, #ff0080 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.billing-price-amount {
  color: inherit;
  text-align: center;
  font-family: Inter;
  font-size: 37.836px;
  font-style: normal;
  font-weight: 700;
  line-height: 45.404px; /* 120% */
  letter-spacing: -0.757px;
}

.billing-price-per {
  color: inherit;
  text-align: center;
  font-family: Inter;
  font-size: 15.135px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.702px; /* 150% */
}

.billing-list-item {
  color: inherit;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 22.702px; /* 150% */
}
