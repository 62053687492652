
.upgrade-modal-bg {
  min-height: 600px;
  border-radius: 8px;
  border: 3px solid #F9FAFB;
  padding: 2.75rem;
  background: #F9FAFB;
  box-shadow: -12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20);
}

.upgrade-top-logo {
  max-width: 3rem;
  height: auto;
}

.upgrade-top-title {
  color: #FFF;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.163px;
}

.upgrade-title {
  color: #FFF;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.218px;
}

.upgrade-text {
  color: #CED4DA;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.109px;
}

.upgrade-features-box {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid rgba(233, 236, 239, 0.75);
  background: #19191F;
  padding: 1.75rem 2rem 1.75rem 2rem;
  min-height: 560px;
}

.upgrade-features-title {
  color: #FFF;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.136px;
}

.upgrade-features-list {
  color: inherit;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.upgrade-features-subtext {
  color: #CED4DA;
  max-width: 400px;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.082px;
}