@tailwind base;
@tailwind components;
@tailwind utilities;

.kpiBoxShadow {
   box-shadow: 0px 2px 6px 2px rgba(135, 135, 135, 0.4);
}

@media screen and (min-width: 320px) {
   .kpiWrapper {
      height: 100px;
      width: 100px;
   }

   .kpiMargin {
      margin-top: 8px;
   }
}

@media screen and (min-width: 380px) {
   .kpiWrapper {
      height: 130px;
      width: 130px;
   }

   .kpiMargin {
      margin-top: 16px;
   }
}

@media screen and (min-width: 480px) {
   .kpiWrapper {
      height: 140px;
      width: 140px;
   }
}


