@tailwind base;
@tailwind components;
@tailwind utilities;

.umTableShadow {
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(25px);
}

.umBgPrimary {
  background: linear-gradient(135deg, #ff0080 0%, #7928ca 100%);
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
}
