@tailwind base;
@tailwind components;
@tailwind utilities;

.scoreKeeperPrimaryBg {
  background: linear-gradient(358deg, #7928ca, #ff0080);
}

.tableShadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.userPagination button:not(disabled) {
  box-shadow: 1px 1px 1px #8888;
}

.userPagination .Mui-selected {
  background: linear-gradient(300deg, #7928ca, #ff0080);
  color: white;
}
