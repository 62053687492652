.errorContainer {
  display: grid;
  grid-template-areas:
    "imageDiv"
    "textDiv";
}

.textContainer {
  grid-area: textDiv;
}

.imgContainer {
  grid-area: imageDiv;
}

@media screen and (min-width: 768px) {
  .errorContainer {
    grid-template-areas: "textDiv imageDiv";
  }
}
