.top-header {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.top-logo {
  max-width: 2rem;
  height: auto;
}

.top-title {
  color: #000;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header {
  color: #343A40;
  text-align: center;
  font-family: Roboto;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.272px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.subheader {
  width: 718px;
  text-align: center;
  color: #5C5C5C;
  text-align: center;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
}