.dialog-paper {
  color: #FFF;
  min-width: 972px;
  min-height: 700px;
  padding: 40px;
  border-radius: 8px;
  border-width: 3px;
  background :linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%);
  box-shadow: -12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(5px),
}

.text-gradient {
  background: linear-gradient(274deg, #2152FF 0%, #21D4FD 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-box {
  display: flex;
  flex-direction: column;
  padding: 20px 32px 20px 32px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid rgba(233, 236, 239, 0.30);
  background: #414143 !important;
}

.connected-app .css-1s2u09g-control {
  background: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
