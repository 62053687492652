.scorecardBg {
  background: radial-gradient(
      18.33% 57.94% at 26.41% 43.24%,
      rgba(255, 255, 255, 0) 22.29%,
      rgba(0, 37, 100, 0.5) 81.09%
    ),
    url("../../img/sc-bg.png") !important;
  background-repeat: no-repeat !important;
  filter: blur(1px) !important;
  -webkit-filter: blur(1px) !important;
  background-size: cover !important;
}

.scorecardDarkBoxes {
  color: #fff !important;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(17px);
}

.scorecardLightBoxes {
  color: #000 !important;
  border-color: #000 !important;
}

.scorecardDarkBoxes hr,
.scorecardDarkBoxes * hr {
  height: 2px !important;
  border-color: #fff !important;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f5f5f5 46.87%, rgba(255, 255, 255, 0) 99.94%) !important;
  margin-bottom: 0 !important;
}

.scoretable * td,
.scoretable * th {
  border-bottom: 0px !important;
}

/* For Removal **/
.statsPosition {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.statsInfoContainer {
   background: linear-gradient(156.28deg, rgba(255, 255, 255, 0.4) 6.82%, rgba(255, 255, 255, 0.1) 93.76%);
   box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.25);
}

.statsTableShadow {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32);
}


/* Charts */
.apexcharts-inner {
  transform: translate(22px, 30px);
}

.apexcharts-radar-series .apexcharts-plot-series {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .scorecardBg {
    background-position: center;
  }
}
