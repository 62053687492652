@tailwind base;
@tailwind components;
@tailwind utilities;

/* Component Styles */
.firstBtn {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}

.secondBtn {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}

.bgImgContainer {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
}

.badgeShadow {
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.102);
}

.addBtn {
  background: linear-gradient(
    135deg,
    #7928ca 3.96%,
    #ff0080 88.5%,
    #ed058a 100%
  ) !important;
  color: #fff !important;
  margin-right: 10px !important;
}

.cancelBtn {
  background: linear-gradient(
    311.91deg,
    #969696 41.78%,
    rgba(150, 150, 150, 0) 166.78%
  ) !important;
  color: #fff !important;
}

.selected-shadow {
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.25) !important;
}

.dashed-container {
  border: 1px dashed rgba(0, 0, 0, 0.2);
}

.input-radius .MuiOutlinedInput-root {
  border-radius: 9px !important;
}

.input-radius .MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.grayscale-image {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
