@tailwind base;
@tailwind components;
@tailwind utilities;

.mainBoxShadow {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.shadowUnderlined > div > div {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.headerIconPrimaryBg {
background-image: linear-gradient(135deg, #FF0080 0%, #7928CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
-moz-background-clip: text;
-moz-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
background-size: 100%;
background-repeat: repeat;
}


@media screen and (max-width: 400px) {
  .xsmallWidth {
    width: 250px;
  }

  .smallPadding {
    padding: 0.25rem;
  }
}