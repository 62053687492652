.headerIconPrimaryBg {
  background: linear-gradient(135deg, #ff0080 0%, #7928ca 100%);
}

.feedContainer > div:first-child {
  width: 100%;
}

.feedBoxShadow {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
}

.feedButtonPrimaryGradient {
  background: linear-gradient(95.76deg, #ee238a -1.6%, #922790 105.5%);
}

.feedBtnBoxShadow {
  box-shadow: 0px 4px 8px rgba(238, 35, 138, 0.19);
}

/* Feed Story Component */
.addStoryBtn {
  background: linear-gradient(135deg, #ff0080 0%, #7928ca 100%),
    -webkit-linear-gradient(135deg, #ff0080 0%, #7928ca 100%) !important;
  -moz-background-clip: text !important;
  -moz-background-fill-color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  fill: #7928ca !important;
}
.social-share {
  text-align: right;
  width: 100%;
}

.job-details,
.job-details-sm-display {
  display: none !important;
}
.job-details-sm-hide {
  display: block;
}

@media only screen and (max-width: 600px) {
  .images-carousel-sm {
    position: relative;
  }

  .job-details {
    display: block !important;
    position: absolute;
    bottom: 65px;
    left: 12px;
  }
  .job-details-sm-hide {
    display: none !important;
  }
  .job-details-sm-display {
    display: block !important;
  }
  .carousel-images p {
    color: #fff;
  }
}
