@tailwind base;
@tailwind components;
@tailwind utilities;

.boxShadow {
  box-shadow: 0px 2px 6px 2px rgba(135, 135, 135, 0.4);
}

.bottombar {
  width: 20%;
}

.iconActive {
  color: white;
}

.iconNotActive {
  color: #9b9b9b;
}

/* Appbar */
.appBarBoxShadow {
  box-shadow: 0px 1px 4px 1px rgba(135, 135, 135, 0.8);
}

.linkHover:hover {
  color: #c94b8b;
}

@media screen and (max-width: 400px) {
  .bottomNavTextIcon {
    display: none;
  }
}
