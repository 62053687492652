.swal-container {
  z-index: 2060 !important
}

.swal2-modal {
  width: 600px;
  border-radius: 8px;
  border: 2px solid rgba(252, 252, 255, 0.41);
  background: linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%);
  background-repeat: no-repeat;
  background-image: url(../../img/notification_bg.svg), linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%);
  box-shadow: -12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(15px);
}

.swal2-title {
  color: #E9ECEF;
  text-align: center;
  font-size: 1.75em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
}

.swal2-html-container {
  color: #CED4DA !important;
  font-size: 1em !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.28px !important;
  margin-top: 2.5em !important;
}

.swal2-actions {
  width: 100%;
  display: flex;
  padding: 3em 2em 2.5em 2em;
}

.swal2-confirm {
  border-radius: 12px;
  background: #04549C !important;
  display: flex;
  height: 59px;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.swal2-cancel {
  border-radius: 12px;
  display: flex;
  height: 59px;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.swal2-deny {
  border-radius: 12px;
  display: flex;
  height: 59px;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}