.color-picker .react-colorful {
  width: 100%;
  padding: 0.75rem;
  border-radius: 1rem;
  background: #14172a;
}

.color-picker .react-colorful__saturation {
  margin: 1rem 0;
  border-radius: 0.25rem;
  border-bottom: none;
}

.color-picker .react-colorful__hue {
  order: -1;
}

.color-picker .react-colorful__hue,
.color-picker .react-colorful__alpha {
  height: 1rem;
  border-radius: 0.25rem;
}

.color-picker .react-colorful__hue-pointer,
.color-picker .react-colorful__alpha-pointer {
  width: 1.5rem;
  height: 1.5rem;
}