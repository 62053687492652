/** Styles copied from Figma dev mode */

.cardPaper {
  width: 100% !important;
  overflow: hidden;
  border-radius: 8px !important;
  background: #f9fafb !important;
  /* box-shadow: 12px 12px 24px 0px rgba(178, 178, 180, 0.24) !important; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
}

.cardImage {
  width: 100%;
  margin: 0 !important;
  border-radius: 0 !important;
  object-fit: cover !important;
}

.cardContent {
  padding: 28px 16px 28px 16px !important;
  user-select: none;
}

.cardTitle {
  color: var(--theme-dark-default, #16192c) !important;
  font-family: Roboto !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  margin-right: 8px !important;
}

.cardText {
  color: var(--text-body-light, #425466) !important;
  font-feature-settings: "salt" on !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  margin-top: 4px !important;
  min-height: 70px !important;
  max-height: 70px !important;
  overflow-y: hidden;
}

.cardText:hover {
  max-height: 500px !important;
}
