@tailwind base;
@tailwind utilities;
@tailwind components;

.calHolContainerShadow {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
}

.calHolEventsShadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.holItemGradient {
  background: linear-gradient(135deg, #ff0080 0%, #7928ca 100%);
}
