/* Main Leadeboard Component (index) */
.lbDarkBg {
  background: linear-gradient(270deg, #141727, #3a416f);
}

.lbTabActive {
  background: linear-gradient(165deg, #ee238a, #922790);
}

/* Leaders Component */
.rankImageShadow {
  box-shadow: 0px 18px 36px 5px #00000040;
}

.accdLeadKpiWrapper {
  display: block;
  margin: 0 0 2rem;
}

.accdLeadKpiWrapper div:not(:last-child) {
  margin-bottom: 1rem;
}

.accdKpiShadow {
  box-shadow: 0px 2px 6px 2px rgba(135, 135, 135, 0.4);
}

/* Progress Bar Component */
.barPrimaryBg {
  background: linear-gradient(180deg, #ee238a 0%, #922790 100%);
}

.pbBoxShadow {
  box-shadow: 0px 2px 6px 2px rgba(135, 135, 135, 0.4);
}

/* Chart Styles Override*/

.apexcharts-canvas {
  width: 260px !important;
}

.apexcharts-text.apexcharts-datalabel-label {
  font-size: 20px !important;
}

.apexcharts-text.apexcharts-datalabel-value {
  font-size: 30px !important;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin-top: -25px;
  right: 20px !important;
}

.apexcharts-legend-series {
  margin: 2px 1px !important;
}

.apexcharts-legend-text {
  font-size: 12px !important;
}

@media screen and (min-width: 400px) {
  .apexcharts-canvas {
    width: 320px !important;
  }
  .apexcharts-text.apexcharts-datalabel-label {
    font-size: 22px !important;
  }
  .apexcharts-text.apexcharts-datalabel-value {
    font-size: 34px !important;
  }
  .apexcharts-legend-text {
    font-size: 14px !important;
  }
  .apexcharts-legend-series {
    margin: 4px 2px !important;
  }
}

@media screen and (min-width: 480px) {
  .apexcharts-canvas {
    width: 350px !important;
  }
  .apexcharts-text.apexcharts-datalabel-label {
    font-size: 24px !important;
  }
  .apexcharts-text.apexcharts-datalabel-value {
    font-size: 38px !important;
  }
  .apexcharts-legend-text {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 768px) {
  .apexcharts-canvas {
    width: 420px !important;
  }

  .apexcharts-text.apexcharts-datalabel-label {
    font-size: 28px !important;
  }

  .apexcharts-text.apexcharts-datalabel-value {
    font-size: 42px !important;
  }

  .apexcharts-legend-text {
    font-size: 18px !important;
  }

  .apexcharts-legend-series {
    margin: 8px 4px !important;
  }
}

@media screen and (min-width: 1200px) {
  .apexcharts-legend-text {
    font-size: 20px !important;
  }

  .apexcharts-legend-series {
    margin: 10px 5px !important;
  }

  .kpiChartLegendValue {
    row-gap: 12.6px !important;
  }
}

.coloredProgress1 {
  border-radius: 10vh;
  border: 0.2vh solid rgba(255, 251, 26, 0.6);
  background: radial-gradient(
      50% 100% at 50% 0%,
      rgba(255, 252, 0, 0.3) 0%,
      rgba(255, 252, 0, 0) 100%
    ),
    rgba(255, 252, 0, 0.4);
  box-shadow: -4px -4px 8px 0px rgba(255, 252, 0, 0.2) inset,
    0px 0px 24px 0px rgba(255, 252, 0, 0.1) inset,
    0px 0px 2vh 0px rgba(255, 252, 0, 0.4);
  backdrop-filter: blur(16px);
  padding: 1% 2% 1% 1%;
}

.coloredProgress2 {
  border-radius: 10vh;
  border: 0.2vh solid rgba(131, 209, 225, 0.8);
  background: radial-gradient(
      50% 100% at 50% 0%,
      rgba(110, 202, 220, 0.2) 0%,
      rgba(110, 202, 220, 0) 100%
    ),
    rgba(131, 209, 225, 0.5);
  box-shadow: -3.59077px -3.59077px 7.18154px 0px rgba(110, 202, 220, 0.2) inset,
    0px 0px 21.54461px 0px rgba(110, 202, 220, 0.1) inset,
    0px 0px 2vh 0px rgba(110, 202, 220, 0.4);
  backdrop-filter: blur(16px);
  padding: 1% 2% 1% 1%;
}

.coloredProgress3 {
  border-radius: 10vh;
  border: 0.2vh solid rgba(255, 163, 26, 0.8);
  background: radial-gradient(
      50% 100% at 50% 0%,
      rgba(255, 153, 0, 0.2) 0%,
      rgba(255, 153, 0, 0) 100%
    ),
    rgba(255, 163, 26, 0.5);
  box-shadow: -3.59077px -3.59077px 7.18154px 0px rgba(255, 153, 0, 0.2) inset,
    0px 0px 21.54461px 0px rgba(255, 153, 0, 0.1) inset,
    0px 0px 2vh 0px rgba(255, 153, 0, 0.4);
  backdrop-filter: blur(16px);
  padding: 1% 2% 1% 1%;
}

.noColorProgress {
  border-radius: 10vh;
  padding: 1% 2% 1% 1%;
}
