.slick-dots li {
  width: 1vw;
  margin: 0 0.25vw;
}

.slick-dots li button:before {
  font-size: 1vw;
  line-height: 1;
  width: 1vw;
  height: 1vw;
}

.slick-track {
  overflow: hidden;
}
