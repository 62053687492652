.avatarImg1v1 {
  height: 300px;
}

@media screen and (min-width: 768px) {
  .avatarImg1v1 {
    height: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .avatarImg1v1 {
    height: 550px;
  }
}

@media screen and (min-width: 2560px) {
  .avatarImg1v1 {
    height: 720px;
  }
}
