@font-face {
  font-family: "Mark"; /*Can be any text*/
  src: local("Mark"), url("../../fonts/Mark.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium Web"; /*Can be any text*/
  src: local("TitilliumWeb-Bold"), url("../../fonts/TitilliumWeb-Bold.ttf") format("truetype");
}

.freemiumChallengeWrapper {
  display: grid;
  grid-template-areas:
    "backgroundPic"
    "card";
  align-items: center;
  grid-row-gap: 1rem;
}

.challengeWrapper {
  grid-area: card;
}

.backgroundWrapper {
  grid-area: backgroundPic;
}

.formShadow {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
}

@media screen and (min-width: 1024px) {
  .freemiumChallengeWrapper {
    grid-template-areas: none;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0rem;
    grid-column-gap: 1rem;
  }

  .challengeWrapper {
    grid-column: 1/2;
  }

  .backgroundWrapper {
    grid-column: 2/3;
    margin-bottom: 0;
  }
}

/* Leaders Component */
.rankImageShadow {
  box-shadow: 0px 18px 36px 5px #00000040;
}

.accdLeadKpiWrapper {
  display: block;
  margin: 0 0 2rem;
}

.accdLeadKpiWrapper div:not(:last-child) {
  margin-bottom: 1rem;
}

.accdKpiShadow {
  box-shadow: 0px 2px 6px 2px rgba(135, 135, 135, 0.4);
}

/* Progress Bar Component */
.barPrimaryBg {
  background: linear-gradient(180deg, #ee238a 0%, #922790 100%);
}

.pbBoxShadow {
  box-shadow: 0px 2px 6px 2px rgba(135, 135, 135, 0.4);
}

/* Chart Styles Override*/

.apexcharts-canvas {
  width: 300px !important;
}

.apexcharts-text.apexcharts-datalabel-label {
  font-size: 20px !important;
}

.apexcharts-text.apexcharts-datalabel-value {
  font-size: 30px !important;
}

.floating-btn {
  height: 55px;
  width: 55px;
  line-height: 55px;
  position: fixed !important;
  bottom: 100px !important;
  right: 15px !important;
  color: #fff !important;
  z-index: 9999;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin-top: -25px;
  right: 20px !important;
}

.apexcharts-legend-series {
  margin: 2px 1px !important;
}

.apexcharts-legend-text {
  font-size: 12px !important;
}

.message-board {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 1rem;
  position: absolute;
  z-index: 2;
}

.message-board-box {
  min-height: 320px;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.messageboard-overlay {
  position: fixed;
  z-index: 333;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.message-board-bottom {
  left: 0;
  bottom: -160px;
  padding-left: 40px;
  padding-right: 40px;
  width: 28%;
}

.message-board-top {
  left: 0px !important;
  right: 0px !important;
  margin: auto !important;
  top: 0px !important;
  bottom: 0px !important;
  width: 30%;
}

@media screen and (max-width: 480px) {
  .floating-btn {
    display: block !important;
  }
}

@media screen and (max-width: 800px) {
  .message-board-top {
    width: 60%;
  }
}

@media screen and (min-width: 400px) {
  .apexcharts-canvas {
    width: 350px !important;
  }
  .apexcharts-text.apexcharts-datalabel-label {
    font-size: 22px !important;
  }
  .apexcharts-text.apexcharts-datalabel-value {
    font-size: 34px !important;
  }
  .apexcharts-legend-text {
    font-size: 14px !important;
  }
  .apexcharts-legend-series {
    margin: 4px 2px !important;
  }
}

@media screen and (min-width: 480px) {
  .apexcharts-text.apexcharts-datalabel-label {
    font-size: 24px !important;
  }
  .apexcharts-text.apexcharts-datalabel-value {
    font-size: 38px !important;
  }
  .apexcharts-legend-text {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 768px) {
  .apexcharts-canvas {
    width: 420px !important;
  }

  .apexcharts-text.apexcharts-datalabel-label {
    font-size: 28px !important;
  }

  .apexcharts-text.apexcharts-datalabel-value {
    font-size: 42px !important;
  }

  .apexcharts-legend-text {
    font-size: 18px !important;
  }

  .apexcharts-legend-series {
    margin: 8px 4px !important;
  }
}

@media screen and (min-width: 1200px) {
  .apexcharts-legend-text {
    font-size: 20px !important;
  }

  .apexcharts-legend-series {
    margin: 10px 5px !important;
  }

  .kpiChartLegendValue {
    row-gap: 12.6px !important;
  }
}

@media screen and (min-width: 1920px) {
  .message-board-bottom {
    bottom: -180px;
  }
}

@media only screen and (max-width: 780px) {
  .freemiumContainer {
    flex-direction: column;
    padding-bottom: 100px;
  }
  .freemiumArea {
    width: 100% !important;
  }
  .realTime-container {
    width: 100% !important;
  }
}

/* Keyframes */

@keyframes cloudMoves {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
