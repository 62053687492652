.playerhandbook .ql-editor {
  min-height: 520px !important;
  max-height: 100% !important;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}

.playerhandbook h1,
.playerhandbook h2,
.playerhandbook h3,
.playerhandbook h4,
.playerhandbook h5,
.playerhandbook h6 {
  margin-top: 0;
  margin-bottom: 0.5em; /* Adjust as needed */
  font-weight: bold;
}

.playerhandbook h1 {
  font-size: 2em;
}

.playerhandbook h2 {
  font-size: 1.5em;
}

.playerhandbook h3 {
  font-size: 1.17em;
}

.playerhandbook h4 {
  font-size: 1em;
}

.playerhandbook h5 {
  font-size: 0.83em;
}

.playerhandbook h6 {
  font-size: 0.67em;
}

.playerhandbook p {
  margin-top: 0;
  margin-bottom: 1em;
}

.playerhandbook table {
  margin-bottom: 20px;
  border-collapse: collapse;
  width: 100%;
}

.playerhandbook th,
.playerhandbook td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
