.messsageContainer {
  display: flex;
}

.chats {
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 70vh !important;
  min-height: 70vh !important;
}

.chats::-webkit-scrollbar {
  width: 10px;
  border-radius: 0px !important;
}

.chatArea {
  flex: "2";
  max-height: 70vh !important;
  min-height: 70vh !important;
}

.chatArea .MuiCardHeader-root {
  border-radius: 1rem 1rem 0px 0px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
  padding: 1rem 1.5rem;
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05);
}
.send-message-btn {
  background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
  padding: 0.25rem 0.5rem 0.5rem 0.5rem !important;
}
.send-message-btn svg {
  transform: rotate(-40deg) !important;
}

.cardarea-body {
  height: 370px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cardarea-footer,
.cardarea-body {
  padding: 1rem 1.5rem !important;
}

.send-message-input {
  width: 100% !important;
}

.image-preview {
  margin-top: 10px;
  width: 250px;
  height: 200px;
}

.image-preview img {
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;
}

.message-box {
  padding: 0.5rem 1rem !important;
  border-radius: 1rem;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  border: 0 solid rgba(0, 0, 0, 0.125);
  margin: 1rem 0rem;
  max-width: 60%;
  min-width: fit-content;
  width: fit-content;
}

.message-box .sender-name {
  margin: 0px 0px 0px !important;
  color: #67748e !important;
  font-size: 12px !important;
  font-weight: bold;
}

.message-box .message-content {
  margin: 0px 0px 2px !important;
  color: #67748e !important;
  font-size: 12px !important;
}

.message-image {
  margin: 0px 0px 2px !important;
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto;
}

.message-image-container {
  width: 250px;
  height: auto;
  margin: 0.35rem 0px !important;
}

.message-typing {
  margin: 0px 0px 2px !important;
  color: #67748e !important;
  font-size: 14px !important;
}

.message-box span:last-child {
  margin: 0px 0px 2px !important;
  color: #67748e !important;
  font-size: 10px !important;
}

.own-message {
  margin-left: auto;
}

.other-message {
  margin-left: 0px;
}

.other-message div {
  align-items: center;
  justify-content: flex-start;
}

.own-message div {
  justify-content: flex-end;
  align-items: center;
}

.cardarea-footer {
  width: 100% !important;
  display: flex;
  align-items: flex-start !important;
}

.unselected-view {
  position: relative;
  background-image: url("../../img/chat-frame.png");
  height: 70vh;
  background-size: cover;
  background-position: center;
}

.content-view {
  display: flex;
  height: 70vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.display-back-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .display-box,
  .display-back-btn {
    display: block;
  }
  .display-box-none,
  .display-back-btn-none {
    display: none;
  }
}
