@tailwind base;
@tailwind utilities;
@tailwind components;

.heroPrimaryBg {
  background: linear-gradient(180deg, #ee238a, #7928ca);
}

@keyframes scrollEffect {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
