/* Calendar Styles */
.padding {
  cursor: default !important;
  background-color: #fffcff !important;
  box-shadow: none !important;
}

/* Modal */
.calModBoxShadow {
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32);
}