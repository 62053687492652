@tailwind base;
@tailwind components;
@tailwind utilities;

.manualEntryShadow {
  box-shadow: 1px 4px 10px 2px rgba(135, 135, 135, 0.3);
}

.link-box {
  width: 200px;
  background-color: #bcd0e0 !important;
  padding: 7px !important;
  border-radius: 4px;
}

.link-box-child {
  border: 1px dashed #04549c;
  border-radius: 4px;
}

.link-box-child a {
  color: #04549c;
  font-size: 12px;
}

.added-links {
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  padding: 5px 10px;
  margin-right: 5px;
  color: #ced4da;
}

.tooltip-widthh {
  min-width: 15rem !important;
}
