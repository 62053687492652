.pulse-container {
  position: relative;
  height: 5rem;
  width: 5rem;
}

.pulse {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: radial-gradient(circle closest-side,#82c4ec, transparent);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.recording-icon {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}